import React, {Component} from "react";

import {
    Row,
    Col,
    Form,
    Select,
    Button,
    Divider,
    Popover,
    Checkbox,
    Input,
    InputNumber,
} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {Test} from "../../../Provider/Service/Services";

import _ from "lodash";
import PackList from "./TestList";

const {Option, OptGroup} = Select;

class ListItem extends Component {
    state = {
        testData: [],
        testTextData: [],
        testsData: [],
        testPackData: [],
        productId: null,
        sectorId: null,
        multiple: false,
        packlist: [],
        testCount: 2,
        sampleCounts: 0
    };

    componentDidMount() {
        const {
            testPackData,
            testData,
            sectorId,
            multiple,
            productId,
            sampleCount,
        } = this.props;


        this.setState({
            testsData: testData,
            testPackData,
            sectorId,
            multiple,
            productId,
            //testCount: sampleCount,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.testData != this.props.testData ||
            prevProps.testPackData != this.props.testPackData ||
            prevProps.userLab != this.props.userLab ||
            prevProps.sectorId != this.props.sectorId ||
            prevProps.multiple != this.props.multiple ||
            prevProps.productId != this.props.productId ||
            prevProps.samplesData != this.props.samplesData
        ) {
            this.updateData();
            /*
            const {allItem} = this.props;
            if (allItem) {
                this._changeTests(allItem.samples);
            }
             */
        }
    }

    updateData = () => {
        const {
            testPackData,
            testData,
            userLab,
            sectorId,
            multiple,
            sampleCount,
            allItem,
            productId,
            samplesData
        } = this.props;
        //const packlist=[...testPackData,...testData];

        if (!multiple) {
            this.setState({testCount: sampleCount});
        }

        this.setState(
            {
                testsData: testData,
                testPackData,
                userLab,
                sectorId,
                productId: allItem?.products?.[0] || productId,
                multiple,
                packlist: [],

                //testCount: sampleCount,
            },
            () => {

                if (this.props.sectorId === '31479c2a2efc8bd273259ea67f4f5fdc') {
                    this.setState({testCount: 0, multiple: 1});
                    this.props.updateTest({
                        [`${this.props.sampleName}-multiple`]: Number(1),
                        [`${this.props.sampleName}-samplecount`]: 0,
                    });
                } else {
                    this.props.updateTest({
                        [`${this.props.sampleName}-multiple`]: Number(this.state.multiple),
                        [`${this.props.sampleName}-samplecount`]: this.state.testCount,
                    });
                }
                this._changeTests(samplesData);
            }
        );
    };
    testDetails = () => {
        const {testTextData} = this.state;

        return (
            <div className="testDetails">
                <ol>
                    {testTextData.map((item, index) => {
                        if (item.data) {
                            return (
                                <li>
                                    <strong>{item.name}</strong>
                                    <ul>
                                        {item.data.map((items, index) => {
                                            return <li>{items.name}</li>;
                                        })}
                                    </ul>
                                </li>
                            );
                        } else {
                            return <li>{item.name}</li>;
                        }
                    })}
                </ol>
            </div>
        );
    };
    _changeTests = (testsId) => {
        let data = [];
        const packlist = [];
        for (const key in testsId) {
            if (testsId.hasOwnProperty(key)) {
                const element = testsId[key];

                const test = _.find(this.props.testData, {test_id: element});
                const testpack = _.find(this.props.testPackData, {
                    pack_id: element,
                });
                if (test) {
                    data.push({
                        name: test.name,
                        data: null,
                    });
                    packlist.push(test);
                }
                if (testpack) {
                    let subdata = [];

                    for (const key in testpack.tests) {
                        if (testpack.tests.hasOwnProperty(key)) {
                            const element = testpack.tests[key];
                            subdata.push({
                                name: element.name,
                            });
                        }
                    }
                    data.push({
                        name: testpack.name,
                        data: subdata,
                    });
                    packlist.push(testpack);
                }
            }
        }
        const {testPackData, testData} = this.props;

        this.setState({
            testTextData: data,
            testsData: testData,
            testPackData,
            packlist,
        });
    };
    searchTests = (search) => {
        if (search.length > 0) {
            const testsData = _.filter(this.props.testData, (item) => {
                const searchItem = `${item.name} / ${item.name_en} - (${item.method_name})`;
                return searchItem.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            });
            const testPackData = _.filter(this.props.testPackData, (item) => {
                const searchItem = `${item.name} / ${item.name_en}`;
                return searchItem.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            });

            this.setState({
                testsData,
                testPackData,
            });
        } else {
            const {testPackData, testData} = this.props;
            this.setState({
                testsData: testData,
                testPackData,
            });
        }
    };
    onChangeMultiple = (value) => {
        this.props.updateTest({
            [this.props.sampleName]: [],

            [`${this.props.sampleName}-samplecount`]: 2,
        });
        this.setState({
            multiple: Boolean(value),
            testTextData: [],

            formTextData: {
                ...this.state.formTextData,
                tests: "",
            },
            packlist: [],
            testCount: Boolean(value) ? 2 : 0,
        });
    };

    render() {
        const {labData, productData, mainLab} = this.props;

        //console.log('s',allItem.samples)

        const {testPackData, testsData, productId, sectorId} = this.state;

        const productsTests = _.filter(testPackData, {product_id: productId});

        // const revizeTestPack =
        //   productsTests.length > 0 ? productsTests : testPackData;
        const revizeTestPack = productsTests.length > 0 ? productsTests : [];

        // const revizeTest = productsTests.length > 0 ? [] : testsData;
        const revizeTest =
            sectorId != null
                ? _.filter(testsData, {sector_id: sectorId})
                : testsData;

        const labs = labData.map((item, index) => {
            return (
                <Option
                    key={index}
                    value={[item.LabName, item.laboratory_id, item.code]}
                >{`${item.LabName} - ${item.code}`}</Option>
            );
        });
        const products = productData.map((item, index) => {
            return (
                <Option key={index} value={item.product_id}>
                    {item.name}
                </Option>
            );
        });
        const testpacks = revizeTestPack.map((item, index) => {
            return (
                <Option key={item.pack_id} value={item.pack_id}>
                    {item.name}/{item.name_en}
                </Option>
            );
        });
        const tests = _.orderBy(revizeTest, ["sector_name"], ["asc"]).map(
            (item, index) => {
                return (
                    <Option key={item.test_id} value={item.test_id}>
                        {item?.sector_name || "-"} | {item.name}/{item.name_en}{" "}
                        {item?.unit_name || "-"} - ({item?.method_name || "-"}) -
                        (Hassasiyet {item?.number_characters || "-"})
                    </Option>
                );
            }
        );
        const initial_values = _.find(this.props.labData, {
            laboratory_id: this.props.userLabId,
        });
        let userLab = null;
        if (initial_values) {
            userLab = [
                initial_values.LabName,
                initial_values.laboratory_id,
                initial_values.code,
            ];
            this.props.changed({
                [this.props.labName]: userLab,
            });
        }

        return (
            <div>
                <Row gutter={16}>
                    <Col
                        md={2}
                        span={24}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Form.Item
                            label="Ana Lab"
                            name={this.props.mainLabChecked}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            initialValue={mainLab}
                            valuePropName="checked"
                        >
                            <Checkbox disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col md={5} span={24}>
                        <Form.Item
                            name={this.props.labName}
                            label="Laboratuvar"
                            rules={[
                                {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                            ]}
                        >
                            <Select
                                // mode="multiple"
                                showSearch
                                placeholder="Seçiniz"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                disabled={this.props.labDisabled}
                            >
                                {labs}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={5} span={24}>
                        <Form.Item
                            name={this.props.productName}
                            label="Ürünler"
                            rules={[
                                {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                            ]}
                        >
                            <Select
                                //mode="multiple"
                                showSearch
                                placeholder="Seçiniz"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children
                                        ?.toLowerCase()
                                        ?.indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(productId) => this.setState({productId})}
                            >
                                {products}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={5} style={{display: "flex", alignItems: "flex-end"}}>
                        <Form.Item
                            name={`${this.props.sampleName}-multiple`}
                            label="Analiz Tipi"
                            rules={[
                                {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                            ]}
                            //initialValue={Number(this.state.multiple)}
                            initialValue={1}
                        >
                            <Select
                                disabled={(this.state.sectorId === '31479c2a2efc8bd273259ea67f4f5fdc' ? true : false)}
                                placeholder="Seçiniz"
                                filterOption={false}
                                onChange={this.onChangeMultiple}
                            >
                                <Option value={1}>Bir Test veya Paket</Option>
                                <Option value={0}>Birden Fazla Aynı Test (Tank by tank)</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {!this.state.multiple && (
                        <Col md={2} style={{display: "flex", alignItems: "flex-end"}}>
                            <Form.Item
                                name={`${this.props.sampleName}-samplecount`}
                                label="Tank Sayısı"
                                rules={[
                                    {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                                ]}
                                initialValue={this.state.testCount}
                            >
                                <InputNumber
                                    onChange={(text) => this.setState({testCount: text})}
                                    min={2}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col
                        md={2}
                        span={24}
                        style={{display: "flex", alignItems: "flex-end"}}
                    >
                        <Button
                            className="btn-w-80"
                            onClick={this.props.onClick}
                            type="danger"
                        >
                            <PlusOutlined/> Sil
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} span={24}>
                        <Form.Item
                            name={this.props.sampleName}
                            label="Test ve Test Paketleri "
                            style={{marginBottom: 20}}
                            rules={[
                                {
                                    required: false,
                                    message: "Lütfen bu alanı boş bırakmayın",
                                },
                            ]}
                        >
                            <Select
                                mode={"multiple"}
                                showSearch
                                placeholder="Seçiniz"
                                filterOption={false}
                                onSearch={this.searchTests}
                                onChange={(testsId) => this._changeTests(testsId)}
                                className="test-paketleri-all-select"
                            >
                                {/*
                                    {
                                        this.state.multiple && (
                                            <OptGroup label="Test Paketleri">{testpacks}</OptGroup>
                                        )
                                    }
                                    <OptGroup label="Testler">{tests}</OptGroup>
                                */}
                                <OptGroup label="Test Paketleri">{testpacks}</OptGroup>
                                <OptGroup label="Testler">{tests}</OptGroup>
                            </Select>
                        </Form.Item>

                        <PackList data={this.state?.packlist || []}/>
                    </Col>
                </Row>
                <Divider/>
            </div>
        );
    }
}

export default ListItem;
